import { locationInstance, instance } from './api';

import {
  AREA, CITY, CONTINENT,
  COUNTRY, PLACE, STATE,
  SUBCONTINENT,
} from '../../data/directory/topicTypes';

import {
  getAPICachingTime, returnAPICachingData,
} from '../../helpers/helpers';
import {
  copy, removeEmptyProperties,
} from '../../helpers/commonHelpers';
import { cachingVeryHigh } from '../../data/webPageData';

const caches = {
  continentsList: {},
  subcontinentsList: [],
  countriesList: [],
  statesList: [],
  citiesList: [],
  areasList: [],
  placesList: [],
  locationsSearch: [],
};

export default class LocationsSvc {
  static async getContinents() {
    if (caches.continentsList.response && caches.continentsList.expDate > Date.now()) {
      return caches.continentsList.response;
    }

    try {
      const { data: { continents } } = await locationInstance.get('continents');

      caches.continentsList = {
        expDate: getAPICachingTime(cachingVeryHigh),
        response: continents,
      };

      return continents;
    } catch (e) {
      return [];
    }
  }

  static async getContinent(slug, sentimentPeriod) {
    try {
      const key = `continent_${slug}`;

      if (caches[key] && caches[key].response && caches[key].expDate > Date.now()) {
        return copy(caches[key].response);
      }

      const path = typeof slug !== 'number' ? 'continents/by_slug' : `continents/${slug}`;
      const { data } = await locationInstance.get(path, {
        params: {
          slug,
          sentiment_period: sentimentPeriod,
        },
      });

      caches[key] = {
        expDate: getAPICachingTime(cachingVeryHigh),
        response: (data && data.continents && data.continents[0]) || null,
      };
      return copy(caches[key].response);
    } catch (e) {
      return null;
    }
  }

  static async getSubContinents(params = {}) {
    if (params && typeof params !== 'object') {
      throw new TypeError('Params must be an object');
    }

    try {
      const { per_page: perPage } = params;
      if ('id' in params) {
        params.continent_id = params.id;
        delete params.id;
      }

      const cacheData = returnAPICachingData(caches, 'subcontinentsList', params);
      if (cacheData) return cacheData;

      const { data, headers } = await locationInstance.get('subcontinents', {
        params,
        headers: {
          // This is a stub header added for CORS headers
          // With this we could force browser request access for custom header
          // in preflight OPTIONS request
          total: '*',
        },
      });

      caches.subcontinentsList.unshift({
        params,
        expDate: getAPICachingTime(cachingVeryHigh),
        response: {
          assets: data.subcontinents,
          totalPages: Math.ceil(headers.total / perPage) || null,
        },
      });

      return {
        assets: data.subcontinents,
        totalPages: Math.ceil(headers.total / perPage) || null,
      };
    } catch {
      return {
        assets: [],
        totalPages: null,
      };
    }
  }

  static async getSubContinentsAlphabetHeaders({ id = '' }) {
    const key = `subcontinentHeaders_${id}`;

    if (caches[key] && caches[key].response && caches[key].expDate > Date.now()) {
      return copy(caches[key].response);
    }

    try {
      const response = await locationInstance.get('subcontinents', {
        params: {
          pagination_alphabet_header: true,
          continent_id: id,
        },
        headers: {
          // This is a stub header added for CORS headers
          // With this we could force browser request access for custom header
          // in preflight OPTIONS request
          total: '*',
        },
      });

      caches[key] = {
        expDate: getAPICachingTime(cachingVeryHigh),
        response: {
          alphabetHeaders: (
            (response && response.data && response.data.pagination_alphabet_header) || []
          ),
          totalItems: parseInt(response.headers.total, 10),
        },
      };
      return copy(caches[key].response);
    } catch {
      return {
        alphabetHeaders: [],
        totalItems: 0,
      };
    }
  }

  static async getSubContinent(slug, sentimentPeriod) {
    try {
      const key = `subcontinent_${slug}`;

      if (caches[key] && caches[key].response && caches[key].expDate > Date.now()) {
        return copy(caches[key].response);
      }

      const path = typeof slug !== 'number' ? 'subcontinents/by_slug' : `subcontinents/${slug}`;
      const { data } = await locationInstance.get(path, {
        params: {
          slug,
          sentiment_period: sentimentPeriod,
        },
      });

      caches[key] = {
        expDate: getAPICachingTime(cachingVeryHigh),
        response: (data && data.subcontinents && data.subcontinents[0]) || null,
      };

      return copy(caches[key].response);
    } catch (e) {
      return null;
    }
  }

  static async getCountries(params = {}) {
    if (params && typeof params !== 'object') {
      throw new TypeError('Params must be an object');
    }

    const { per_page: perPage } = params;
    let data;
    let headers;

    if ('id' in params) {
      params.parent_id = params.id;
      delete params.id;
    }
    if (!('parent_type' in params)) params.parent_type = 'subcontinent';
    if (params.noParent) {
      delete params.noParent;
      delete params.parent_type;
    }

    const cacheData = returnAPICachingData(caches, 'countriesList', params);
    if (cacheData) return cacheData;

    try {
      ({ data, headers } = await locationInstance.get('countries', {
        params,
        headers: {
          // This is a stub header added for CORS headers
          // With this we could force browser request access for custom header
          // in preflight OPTIONS request
          total: '*',
        },
      }));
    } catch (e) {
      data = {
        countries: [],
      };
      headers = {
        total: 0,
      };
    }

    caches.countriesList.unshift({
      params,
      expDate: getAPICachingTime(cachingVeryHigh),
      response: {
        assets: data.countries,
        totalPages: Math.ceil(headers.total / perPage) || null,
      },
    });

    return {
      assets: data.countries,
      totalPages: Math.ceil(headers.total / perPage) || null,
    };
  }

  static async getCountriesAlphabetHeaders({ id = '', parent_type = 'subcontinent' }) {
    let data;
    let headers;

    const params = {
      pagination_alphabet_header: true,
      ...(id && {
        parent_id: id,
      }),
      ...(parent_type && {
        parent_type,
      }),
    };

    const cacheData = returnAPICachingData(caches, 'countriesList', params);
    if (cacheData) return cacheData;

    try {
      ({ data, headers } = await locationInstance.get('countries', {
        params,
        headers: {
          // This is a stub header added for CORS headers
          // With this we could force browser request access for custom header
          // in preflight OPTIONS request
          total: '*',
        },
      }));
    } catch (e) {
      data = {
        pagination_alphabet_header: [],
      };
      headers = {
        total: 0,
      };
    }

    caches.countriesList.unshift({
      params,
      expDate: getAPICachingTime(cachingVeryHigh),
      response: {
        alphabetHeaders: (
          (data && data.pagination_alphabet_header) || []
        ),
        totalItems: parseInt(headers.total, 10),
      },
    });

    return {
      alphabetHeaders: (
        (data && data.pagination_alphabet_header) || []
      ),
      totalItems: parseInt(headers.total, 10),
    };
  }

  static async getCountry(slug, sentimentPeriod) {
    try {
      const key = `country_${slug}`;

      if (caches[key] && caches[key].response && caches[key].expDate > Date.now()) {
        return copy(caches[key].response);
      }

      const path = typeof slug !== 'number' ? 'countries/by_slug' : `countries/${slug}`;
      const { data } = await locationInstance.get(path, {
        params: {
          slug,
          sentiment_period: sentimentPeriod,
        },
      });

      caches[key] = {
        expDate: getAPICachingTime(cachingVeryHigh),
        response: (data && data.countries && data.countries[0]) || null,
      };

      return copy(caches[key].response);
    } catch (e) {
      return null;
    }
  }

  static async getStates(params = {}) {
    if (params && typeof params !== 'object') {
      throw new TypeError('Params must be an object');
    }

    const { per_page: perPage } = params;
    let data;
    let headers;

    if ('id' in params) {
      params.country_id = params.id;
      delete params.id;
    }

    const cacheData = returnAPICachingData(caches, 'statesList', params);
    if (cacheData) return cacheData;

    try {
      ({ data, headers } = await locationInstance.get('states', {
        params,
        headers: {
          // This is a stub header added for CORS headers
          // With this we could force browser request access for custom header
          // in preflight OPTIONS request
          total: '*',
        },
      }));
    } catch (e) {
      data = {
        states: [],
      };
      headers = {
        total: 0,
      };
    }

    caches.statesList.unshift({
      params,
      expDate: getAPICachingTime(cachingVeryHigh),
      response: {
        assets: data.states,
        totalPages: Math.ceil(headers.total / perPage) || null,
      },
    });

    return {
      assets: data.states,
      totalPages: Math.ceil(headers.total / perPage) || null,
    };
  }

  static async getStatesAlphabetHeaders({ id = '' }) {
    const key = `statesHeaders_${id}`;

    if (caches[key] && caches[key].response && caches[key].expDate > Date.now()) {
      return copy(caches[key].response);
    }

    try {
      const { data, headers } = await locationInstance.get('states', {
        params: {
          pagination_alphabet_header: true,
          country_id: id,
        },
        headers: {
          // This is a stub header added for CORS headers
          // With this we could force browser request access for custom header
          // in preflight OPTIONS request
          total: '*',
        },
      });

      caches[key] = {
        expDate: getAPICachingTime(cachingVeryHigh),
        response: {
          alphabetHeaders: (
            (data && data.pagination_alphabet_header) || []
          ),
          totalItems: parseInt(headers.total, 10),
        },
      };

      return copy(caches[key].response);
    } catch {
      return {
        alphabetHeaders: [],
        totalItems: 0,
      };
    }
  }

  static async getState(slug, sentimentPeriod) {
    try {
      const key = `state_${slug}`;

      if (caches[key] && caches[key].response && caches[key].expDate > Date.now()) {
        return copy(caches[key].response);
      }

      const path = typeof slug !== 'number' ? 'states/by_slug' : `states/${slug}`;
      const { data } = await locationInstance.get(path, {
        params: {
          slug,
          sentiment_period: sentimentPeriod,
        },
      });

      caches[key] = {
        expDate: getAPICachingTime(cachingVeryHigh),
        response: (data && data.states && data.states[0]) || null,
      };

      return copy(caches[key].response);
    } catch (e) {
      return null;
    }
  }

  static async getCities(params = {}) {
    if (params && typeof params !== 'object') {
      throw new TypeError('Params must be an object');
    }

    const { per_page: perPage } = params;
    let data;
    let headers;

    if ('id' in params) {
      params.parent_id = params.id;
      delete params.id;
    }
    if (!('parent_type' in params)) params.parent_type = 'state';

    const cacheData = returnAPICachingData(caches, 'citiesList', params);
    if (cacheData) return cacheData;

    try {
      ({ data, headers } = await locationInstance.get('cities', {
        params,
        headers: {
          // This is a stub header added for CORS headers
          // With this we could force browser request access for custom header
          // in preflight OPTIONS request
          total: '*',
        },
      }));
    } catch (e) {
      data = {
        cities: [],
      };
      headers = {
        total: 0,
      };
    }

    caches.citiesList.unshift({
      params,
      expDate: getAPICachingTime(cachingVeryHigh),
      response: {
        assets: data.cities,
        totalPages: Math.ceil(headers.total / perPage) || null,
      },
    });

    return {
      assets: data.cities,
      totalPages: Math.ceil(headers.total / perPage) || null,
    };
  }

  static async getCitiesAlphabetHeaders({ id = '', parent_type = 'state' }) {
    const key = `citiesHeaders_${id}`;

    if (caches[key] && caches[key].response && caches[key].expDate > Date.now()) {
      return copy(caches[key].response);
    }

    try {
      const { data, headers } = await locationInstance.get('cities', {
        params: {
          pagination_alphabet_header: true,
          parent_id: id,
          parent_type,
        },
        headers: {
          // This is a stub header added for CORS headers
          // With this we could force browser request access for custom header
          // in preflight OPTIONS request
          total: '*',
        },
      });

      caches[key] = {
        expDate: getAPICachingTime(cachingVeryHigh),
        response: {
          alphabetHeaders: (
            (data && data.pagination_alphabet_header) || []
          ),
          totalItems: parseInt(headers.total, 10),
        },
      };

      return copy(caches[key].response);
    } catch {
      return {
        alphabetHeaders: [],
        totalItems: 0,
      };
    }
  }

  static async getCity(slug, sentimentPeriod) {
    try {
      const key = `cities_${slug}`;

      if (caches[key] && caches[key].response && caches[key].expDate > Date.now()) {
        return copy(caches[key].response);
      }

      const path = typeof slug !== 'number' ? 'cities/by_slug' : `cities/${slug}`;
      const { data } = await locationInstance.get(path, {
        params: {
          slug,
          sentiment_period: sentimentPeriod,
        },
      });

      caches[key] = {
        expDate: getAPICachingTime(cachingVeryHigh),
        response: (data && data.cities && data.cities[0]) || null,
      };

      return copy(caches[key].response);
    } catch (e) {
      return null;
    }
  }

  static async getAreas(params = {}) {
    if (params && typeof params !== 'object') {
      throw new TypeError('Params must be an object');
    }

    const { per_page: perPage } = params;
    let data;
    let headers;

    if ('id' in params) {
      params.parent_id = params.id;
      delete params.id;
    }
    if (!('parent_type' in params)) params.parent_type = 'city';

    const cacheData = returnAPICachingData(caches, 'areasList', params);
    if (cacheData) return cacheData;

    try {
      ({ data, headers } = await locationInstance.get('areas', {
        params,
        headers: {
          // This is a stub header added for CORS headers
          // With this we could force browser request access for custom header
          // in preflight OPTIONS request
          total: '*',
        },
      }));
    } catch (e) {
      data = {
        areas: [],
      };
      headers = {
        total: 0,
      };
    }

    caches.areasList.unshift({
      params,
      expDate: getAPICachingTime(cachingVeryHigh),
      response: {
        assets: data.areas,
        totalPages: Math.ceil(headers.total / perPage) || null,
      },
    });

    return {
      assets: data.areas,
      totalPages: Math.ceil(headers.total / perPage) || null,
    };
  }

  static async getAreasAlphabetHeaders({ id = '', parent_type = 'city' }) {
    const key = `areasHeaders_${id}`;

    if (caches[key] && caches[key].response && caches[key].expDate > Date.now()) {
      return copy(caches[key].response);
    }

    try {
      const { data, headers } = await locationInstance.get('areas', {
        params: {
          pagination_alphabet_header: true,
          parent_id: id,
          parent_type,
        },
        headers: {
          // This is a stub header added for CORS headers
          // With this we could force browser request access for custom header
          // in preflight OPTIONS request
          total: '*',
        },
      });

      caches[key] = {
        expDate: getAPICachingTime(cachingVeryHigh),
        response: {
          alphabetHeaders: (
            (data && data.pagination_alphabet_header) || []
          ),
          totalItems: parseInt(headers.total, 10),
        },
      };

      return copy(caches[key].response);
    } catch {
      return {
        alphabetHeaders: [],
        totalItems: 0,
      };
    }
  }

  static async getArea(slug, sentimentPeriod) {
    try {
      const key = `area_${slug}`;

      if (caches[key] && caches[key].response && caches[key].expDate > Date.now()) {
        return copy(caches[key].response);
      }

      const path = typeof slug !== 'number' ? 'areas/by_slug' : `areas/${slug}`;
      const { data } = await locationInstance.get(path, {
        params: {
          slug,
          sentiment_period: sentimentPeriod,
        },
      });

      caches[key] = {
        expDate: getAPICachingTime(cachingVeryHigh),
        response: (data && data.areas && data.areas[0]) || null,
      };

      return copy(caches[key].response);
    } catch (e) {
      return null;
    }
  }

  static async getPlaces(params = {}) {
    removeEmptyProperties(params);
    if (params && typeof params !== 'object') {
      throw new TypeError('Params must be an object');
    }

    const { per_page: perPage } = params;
    let data;
    let headers;

    if ('id' in params) {
      params.city_id = params.id;
      delete params.id;
    }

    const cacheData = returnAPICachingData(caches, 'placesList', params);
    if (cacheData) return cacheData;

    try {
      ({ data, headers } = await locationInstance.get('places', {
        params,
        headers: {
          // This is a stub header added for CORS headers
          // With this we could force browser request access for custom header
          // in preflight OPTIONS request
          total: '*',
        },
      }));
    } catch (e) {
      data = {
        places: [],
      };
      headers = {
        total: 0,
      };
    }

    caches.areasList.unshift({
      params,
      expDate: getAPICachingTime(cachingVeryHigh),
      response: {
        assets: data.places,
        totalPages: Math.ceil(headers.total / perPage) || null,
      },
    });

    return {
      assets: data.places,
      totalPages: Math.ceil(headers.total / perPage) || null,
    };
  }

  static async getPlacesAlphabetHeaders({ id = '' }) {
    const key = `placesHeaders_${id}`;

    if (caches[key] && caches[key].response && caches[key].expDate > Date.now()) {
      return copy(caches[key].response);
    }

    try {
      const { data, headers } = await locationInstance.get('places', {
        params: {
          pagination_alphabet_header: true,
          city_id: id,
        },
        headers: {
          // This is a stub header added for CORS headers
          // With this we could force browser request access for custom header
          // in preflight OPTIONS request
          total: '*',
        },
      });

      caches[key] = {
        expDate: getAPICachingTime(cachingVeryHigh),
        response: {
          alphabetHeaders: (
            (data && data.pagination_alphabet_header) || []
          ),
          totalItems: parseInt(headers.total, 10),
        },
      };

      return copy(caches[key].response);
    } catch {
      return {
        alphabetHeaders: [],
        totalItems: 0,
      };
    }
  }

  static async getPlace(slug, sentimentPeriod) {
    try {
      const key = `place_${slug}`;

      if (caches[key] && caches[key].response && caches[key].expDate > Date.now()) {
        return copy(caches[key].response);
      }

      const path = typeof slug !== 'number' ? 'places/by_slug' : `places/${slug}`;
      const { data } = await locationInstance.get(path, {
        params: {
          slug,
          sentiment_period: sentimentPeriod,
        },
      });

      caches[key] = {
        expDate: getAPICachingTime(cachingVeryHigh),
        response: (data && data.places && data.places[0]) || null,
      };

      return copy(caches[key].response);
    } catch (e) {
      return null;
    }
  }

  static async searchLocations({ query }) {
    const params = {
      query,
    };
    const cacheData = returnAPICachingData(caches, 'locationsSearch', params);
    if (cacheData) return cacheData;

    try {
      const { data } = await instance.get('locations_search', {
        params,
      });

      caches.locationsSearch.unshift({
        params,
        expDate: getAPICachingTime(cachingVeryHigh),
        response: data,
      });

      return data;
    } catch (e) {
      return {};
    }
  }

  static async getLocationsByType(type, params = {}) {
    let response;
    switch (type) {
      case CONTINENT: {
        const { prefix = '', ...otherParams } = params;
        response = await this.getContinents(otherParams);
        response = response.filter((item) => (
          item.name.toLowerCase().startsWith(prefix.toLowerCase())
        ));
        break;
      }
      case SUBCONTINENT:
        response = (await this.getSubContinents({
          per_page: 200,
          ...params,
        })).assets;
        break;
      case COUNTRY:
        response = (await this.getCountries({
          per_page: 200,
          parent_type: undefined,
          ...params,
        })).assets;
        break;
      case STATE:
        response = (await this.getStates({
          per_page: 200,
          ...params,
        })).assets;
        break;
      case CITY:
        response = (await this.getCities({
          per_page: 200,
          parent_type: undefined,
          ...params,
        })).assets;
        break;
      case AREA:
        response = (await this.getAreas({
          per_page: 200,
          parent_type: undefined,
          ...params,
        })).assets;
        break;
      case PLACE:
        response = (await this.getPlaces({
          per_page: 200,
          ...params,
        })).assets;
        break;
      default:
        response = [];
        break;
    }
    response.forEach((item) => {
      item.type = type;
    });
    return response;
  }

  static getLocationByType(id, type, sentimentPeriod) {
    return this.typeToLocationMethodMapping.get(type)?.(id, sentimentPeriod);
  }

  static typeToLocationMethodMapping = new Map([
    [CONTINENT, this.getContinent],
    [SUBCONTINENT, this.getSubContinent],
    [COUNTRY, this.getCountry],
    [STATE, this.getState],
    [CITY, this.getCity],
    [AREA, this.getArea],
    [PLACE, this.getPlace],
  ].map((pair) => (
    [pair[0].toLowerCase(), pair[1].bind(this)]
  )));
}
